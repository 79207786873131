
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs,
  onBeforeUnmount
} from 'vue'
import * as faceapi from 'face-api.js'
import { isValidUsername } from '@/utils/validate'
import { useRoute, LocationQuery, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { UserActionTypes } from '@/store/modules/user/action-types'

interface Facedata {
  detection: string
  withBoxes: boolean
  detectFace: string
  timeout: any
  nets: string
  options: any
  videoEl: any
  canvasEl: any
  constraints: object
  webversion: number
}
export default defineComponent({
  setup() {
    const userNameRef = ref(null)
    const passwordRef = ref(null)
    const loginFormRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      activeName: 'second',
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ validator: userNameRef, trigger: 'blur' }],
        password: [{ validator: passwordRef, trigger: 'blur' }]
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
      capsTooltip: false,
      redirect: '',
      otherQuery: {}
    })
    const facedata: Facedata = reactive({
      nets: 'tinyFaceDetector', // 模型
      options: null, // 模型参数
      withBoxes: true, // 框or轮廓
      detectFace: 'detectSingleFace', // 单or多人脸
      detection: 'landmark',
      videoEl: {},
      canvasEl: null,
      timeout: 0,
      // 视频媒体参数配置
      constraints: {
        audio: false,
        video: {
          // ideal（应用最理想的）
          width: {
            min: 200,
            ideal: 40,
            max: 200
          },
          height: {
            min: 240,
            ideal: 40,
            max: 240
          },
          // frameRate受限带宽传输时，低帧率可能更适宜
          frameRate: {
            min: 15,
            ideal: 30,
            max: 60
          },
          // 显示模式前置后置
          facingMode: 'user'
        }
      },
      webversion: 2.2
    })
    const methods = reactive({
      validateUsername: (rule: any, value: string, callback: Function) => {
        if (!isValidUsername(value)) {
          callback(new Error('Please enter the correct user name'))
        } else {
          callback()
        }
      },
      validatePassword: (rule: any, value: string, callback: Function) => {
        if (value.length < 6) {
          callback(new Error('The password can not be less than 6 digits'))
        } else {
          callback()
        }
      },
      checkCapslock: (e: KeyboardEvent) => {
        const { key } = e
        state.capsTooltip =
          key !== null && key.length === 1 && key >= 'A' && key <= 'Z'
      },
      showPwd: () => {
        if (state.passwordType === 'password') {
          state.passwordType = ''
        } else {
          state.passwordType = 'password'
        }
        nextTick(() => {
          (passwordRef.value as any).focus()
        })
      },
      handleLogin: () => {
        (loginFormRef.value as any).validate(async(valid: boolean) => {
          if (valid) {
            state.loading = true
            await store.dispatch(UserActionTypes.ACTION_LOGIN, state.loginForm)
            router
              .push({
                path: state.redirect || '/',
                query: state.otherQuery
              })
              .catch(err => {
                console.warn(err)
              })
            // Just to simulate the time of the request
            setTimeout(() => {
              state.loading = false
            }, 0.5 * 1000)
          } else {
            return false
          }
        })
      },
      // 初始化模型加载
      async fnInit() {
        await faceapi.nets[facedata.nets].loadFromUri('/models') // 算法模型
        await faceapi.loadFaceLandmarkModel('/models')// 轮廓模型
        // 根据算法模型参数识别调整结果
        switch (facedata.nets) {
          case 'ssdMobilenetv1':
            facedata.options = new faceapi.SsdMobilenetv1Options({
              minConfidence: 0.5 // 0.1 ~ 0.9
            })
            break
          case 'tinyFaceDetector':
            facedata.options = new faceapi.TinyFaceDetectorOptions({
              inputSize: 512, // 160 224 320 416 512 608
              scoreThreshold: 0.5 // 0.1 ~ 0.9
            })
            break
          case 'mtcnn':
            facedata.options = new faceapi.MtcnnOptions({
              minFaceSize: 20, // 0.1 ~ 0.9
              scaleFactor: 0.709 // 0.1 ~ 0.9
            })
            break
        }
        // 节点属性化
        facedata.videoEl = document.getElementById('myVideo') as HTMLElement
        facedata.canvasEl = document.getElementById('myCanvas') as HTMLElement
      },
      // 人脸面部勘探轮廓识别绘制
      async fnRunFaceLandmark() {
        if (facedata.videoEl.paused) return clearTimeout(facedata.timeout)
        // 识别绘制人脸信息
        const result = await faceapi[facedata.detectFace](
          facedata.videoEl,
          facedata.options
        ).withFaceLandmarks()
        if (result && !facedata.videoEl.paused) {
          const dims = faceapi.matchDimensions(facedata.canvasEl, facedata.videoEl, true)
          const resizeResult = faceapi.resizeResults(result, dims)
          //       //  在这里准备开始到了人脸的信息
          const ctx = facedata.canvasEl.getContext('2d')
          // 把当前视频帧内容渲染到canvas上
          ctx.drawImage(facedata.videoEl, 0, 0)
          // 转base64格式、图片格式转换、图片质量压缩z
          const imgBase64 = facedata.canvasEl.toDataURL('image', 1)
          await store.dispatch(UserActionTypes.ACTION_FACE_LOGIN, imgBase64)
          if (store.state.user.token) {
            methods.fnClose()
            router
              .push({
                path: state.redirect || '/',
                query: state.otherQuery
              })
            facedata.withBoxes
              ? faceapi.draw.drawDetections(facedata.canvasEl, resizeResult)
              : faceapi.draw.drawFaceLandmarks(facedata.canvasEl, resizeResult)
          } else {
            await store.dispatch(UserActionTypes.ACTION_FACE_LOGIN, imgBase64)
          }
        } else {
          facedata.canvasEl
            .getContext('2d')
            .clearRect(0, 0, facedata.canvasEl.width, facedata.canvasEl.height)
        }

        facedata.timeout = setTimeout(() => methods.fnRunFaceLandmark())
      },
      // 执行检测识别类型
      fnRun() {
        if (facedata.detection === 'landmark') {
          methods.fnRunFaceLandmark()
          return true
        }
      },
      fnOpen: () => {
        state.showDialog = true
        clearTimeout(facedata.timeout)
        facedata.timeout = setTimeout(() => {
          clearTimeout(facedata.timeout)
          navigator.mediaDevices
            .getUserMedia(facedata.constraints)
            .then(methods.fnSuccess)
            .catch(methods.fnError)
        }, 100)
      },
      fnSuccess: (stream: any) => {
        (window as any).stream = stream// 使流对浏览器控制台可用
        facedata.videoEl.srcObject = stream
        facedata.videoEl.play()
      },
      fnClose() {
        state.showDialog = false
        facedata.canvasEl
          .getContext('2d')
          .clearRect(0, 0, facedata.canvasEl.width, facedata.canvasEl.height)
        facedata.videoEl.pause()
        clearTimeout(facedata.timeout)
        if (typeof (window as any).stream === 'object') {
          (window as any).stream.getTracks().forEach((track: any) => track.stop())
          facedata.videoEl.srcObject = null
        }
      },
      fnError(error: string) {
        alert('视频媒体流获取错误' + error)
      }
    })

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {} as LocationQuery)
    }
    watch([() => route.query, () => facedata.nets, () => facedata.detection], ([query, nets, detection]) => {
      if (query) {
        state.redirect = query.redirect?.toString() ?? ''
        state.otherQuery = getOtherQuery(query)
        facedata.detection = detection
        facedata.videoEl.pause()
        facedata.nets = nets
        methods.fnInit()
        setTimeout(() => {
          facedata.videoEl.play()
          setTimeout(() => methods.fnRun(), 1000)
        }, 1000)
      }
    })
    onMounted(() => {
      methods.fnInit()
      if (state.loginForm.username === '') {
        (userNameRef.value as any).focus()
      } else if (state.loginForm.password === '') {
        (passwordRef.value as any).focus()
      }
    })
    onBeforeUnmount(() => {
      methods.fnClose()
    })
    return {
      userNameRef,
      passwordRef,
      loginFormRef,
      ...toRefs(state),
      ...toRefs(methods)
    }
  }
})

<!--
 * @Description: 登录页面
 * @Author: ZY
 * @Date: 2020-12-28 16:27:50
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-28 16:32:33
-->

<template>
  <div class="login-container">
    <el-tabs
      v-model="activeName"
      class="demo-tabs  login-form"
      stretch="true"
    >
      <el-tab-pane
        label="账号密码"
        name="first"
      >
        <el-form
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginRules"
          autocomplete="on"
          label-position="left"
        >
          <div class="title-container">
            <h1 class="title" />
            <!-- <LangSelect
              :isWhite="true"
              class="set-language"
            /> -->
          </div>
          <el-form-item prop="username">
            <span class="svg-container">
              <i class="el-icon-user" />
            </span>
            <el-input
              ref="userNameRef"
              v-model="loginForm.username"
              placeholder="登录账户"
              name="username"
              type="text"
              tabindex="1"
              autocomplete="on"
            />
          </el-form-item>
          <el-tooltip
            v-model="capsTooltip"
            content="Caps lock is On"
            placement="right"
            manual
          >
            <el-form-item prop="password">
              <span class="svg-container">
                <i class="el-icon-lock" />
              </span>
              <el-input
                :key="passwordType"
                ref="passwordRef"
                v-model="loginForm.password"
                :type="passwordType"
                placeholder="登录密码"
                name="password"
                tabindex="2"
                autocomplete="on"
                @keyup="checkCapslock"
                @blur="capsTooltip = false"
                @keyup.enter="handleLogin"
              />
              <span
                class="show-pwd"
                @click="showPwd"
              >
                <svg-icon :name="passwordType === 'password' ? 'eye-off' : 'eye-on'" />
              </span>
            </el-form-item>
          </el-tooltip>

          <el-button
            :loading="loading"
            type="primary"
            style="width:100%; margin-bottom:10px;"
            @click.prevent="handleLogin"
          >
            {{ "登录" }}
          </el-button>
        </el-form>
      </el-tab-pane>
      <el-tab-pane
        label="人脸登录"
        name="second"
      >
        <el-button
          v-if="!showDialog"
          style="width:100%; margin-bottom:10px;margin-left:0"
          type="primary"
          @click.prevent="fnOpen"
        >
          {{ "人脸登录" }}
        </el-button>
        <el-button
          v-if="showDialog"
          style="width:100%; margin-bottom:10px;margin-left:0"
          type="primary"
          @click.prevent="fnClose"
        >
          {{ "取消人脸登录" }}
        </el-button>
      </el-tab-pane>
    </el-tabs>
    <div
      class="see"
      v-show="showDialog"
    >
      <video
        id="myVideo"
        poster=""
        muted
        loop
        preload="true"
        playsinline
        @loadedmetadata="fnRun"
      />
      <canvas id="myCanvas" />
      <canvas id="saveFace" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs,
  onBeforeUnmount
} from 'vue'
import * as faceapi from 'face-api.js'
import { isValidUsername } from '@/utils/validate'
import { useRoute, LocationQuery, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { UserActionTypes } from '@/store/modules/user/action-types'

interface Facedata {
  detection: string
  withBoxes: boolean
  detectFace: string
  timeout: any
  nets: string
  options: any
  videoEl: any
  canvasEl: any
  constraints: object
  webversion: number
}
export default defineComponent({
  setup() {
    const userNameRef = ref(null)
    const passwordRef = ref(null)
    const loginFormRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      activeName: 'second',
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ validator: userNameRef, trigger: 'blur' }],
        password: [{ validator: passwordRef, trigger: 'blur' }]
      },
      passwordType: 'password',
      loading: false,
      showDialog: false,
      capsTooltip: false,
      redirect: '',
      otherQuery: {}
    })
    const facedata: Facedata = reactive({
      nets: 'tinyFaceDetector', // 模型
      options: null, // 模型参数
      withBoxes: true, // 框or轮廓
      detectFace: 'detectSingleFace', // 单or多人脸
      detection: 'landmark',
      videoEl: {},
      canvasEl: null,
      timeout: 0,
      // 视频媒体参数配置
      constraints: {
        audio: false,
        video: {
          // ideal（应用最理想的）
          width: {
            min: 200,
            ideal: 40,
            max: 200
          },
          height: {
            min: 240,
            ideal: 40,
            max: 240
          },
          // frameRate受限带宽传输时，低帧率可能更适宜
          frameRate: {
            min: 15,
            ideal: 30,
            max: 60
          },
          // 显示模式前置后置
          facingMode: 'user'
        }
      },
      webversion: 2.2
    })
    const methods = reactive({
      validateUsername: (rule: any, value: string, callback: Function) => {
        if (!isValidUsername(value)) {
          callback(new Error('Please enter the correct user name'))
        } else {
          callback()
        }
      },
      validatePassword: (rule: any, value: string, callback: Function) => {
        if (value.length < 6) {
          callback(new Error('The password can not be less than 6 digits'))
        } else {
          callback()
        }
      },
      checkCapslock: (e: KeyboardEvent) => {
        const { key } = e
        state.capsTooltip =
          key !== null && key.length === 1 && key >= 'A' && key <= 'Z'
      },
      showPwd: () => {
        if (state.passwordType === 'password') {
          state.passwordType = ''
        } else {
          state.passwordType = 'password'
        }
        nextTick(() => {
          (passwordRef.value as any).focus()
        })
      },
      handleLogin: () => {
        (loginFormRef.value as any).validate(async(valid: boolean) => {
          if (valid) {
            state.loading = true
            await store.dispatch(UserActionTypes.ACTION_LOGIN, state.loginForm)
            router
              .push({
                path: state.redirect || '/',
                query: state.otherQuery
              })
              .catch(err => {
                console.warn(err)
              })
            // Just to simulate the time of the request
            setTimeout(() => {
              state.loading = false
            }, 0.5 * 1000)
          } else {
            return false
          }
        })
      },
      // 初始化模型加载
      async fnInit() {
        await faceapi.nets[facedata.nets].loadFromUri('/models') // 算法模型
        await faceapi.loadFaceLandmarkModel('/models')// 轮廓模型
        // 根据算法模型参数识别调整结果
        switch (facedata.nets) {
          case 'ssdMobilenetv1':
            facedata.options = new faceapi.SsdMobilenetv1Options({
              minConfidence: 0.5 // 0.1 ~ 0.9
            })
            break
          case 'tinyFaceDetector':
            facedata.options = new faceapi.TinyFaceDetectorOptions({
              inputSize: 512, // 160 224 320 416 512 608
              scoreThreshold: 0.5 // 0.1 ~ 0.9
            })
            break
          case 'mtcnn':
            facedata.options = new faceapi.MtcnnOptions({
              minFaceSize: 20, // 0.1 ~ 0.9
              scaleFactor: 0.709 // 0.1 ~ 0.9
            })
            break
        }
        // 节点属性化
        facedata.videoEl = document.getElementById('myVideo') as HTMLElement
        facedata.canvasEl = document.getElementById('myCanvas') as HTMLElement
      },
      // 人脸面部勘探轮廓识别绘制
      async fnRunFaceLandmark() {
        if (facedata.videoEl.paused) return clearTimeout(facedata.timeout)
        // 识别绘制人脸信息
        const result = await faceapi[facedata.detectFace](
          facedata.videoEl,
          facedata.options
        ).withFaceLandmarks()
        if (result && !facedata.videoEl.paused) {
          const dims = faceapi.matchDimensions(facedata.canvasEl, facedata.videoEl, true)
          const resizeResult = faceapi.resizeResults(result, dims)
          //       //  在这里准备开始到了人脸的信息
          const ctx = facedata.canvasEl.getContext('2d')
          // 把当前视频帧内容渲染到canvas上
          ctx.drawImage(facedata.videoEl, 0, 0)
          // 转base64格式、图片格式转换、图片质量压缩z
          const imgBase64 = facedata.canvasEl.toDataURL('image', 1)
          await store.dispatch(UserActionTypes.ACTION_FACE_LOGIN, imgBase64)
          if (store.state.user.token) {
            methods.fnClose()
            router
              .push({
                path: state.redirect || '/',
                query: state.otherQuery
              })
            facedata.withBoxes
              ? faceapi.draw.drawDetections(facedata.canvasEl, resizeResult)
              : faceapi.draw.drawFaceLandmarks(facedata.canvasEl, resizeResult)
          } else {
            await store.dispatch(UserActionTypes.ACTION_FACE_LOGIN, imgBase64)
          }
        } else {
          facedata.canvasEl
            .getContext('2d')
            .clearRect(0, 0, facedata.canvasEl.width, facedata.canvasEl.height)
        }

        facedata.timeout = setTimeout(() => methods.fnRunFaceLandmark())
      },
      // 执行检测识别类型
      fnRun() {
        if (facedata.detection === 'landmark') {
          methods.fnRunFaceLandmark()
          return true
        }
      },
      fnOpen: () => {
        state.showDialog = true
        clearTimeout(facedata.timeout)
        facedata.timeout = setTimeout(() => {
          clearTimeout(facedata.timeout)
          navigator.mediaDevices
            .getUserMedia(facedata.constraints)
            .then(methods.fnSuccess)
            .catch(methods.fnError)
        }, 100)
      },
      fnSuccess: (stream: any) => {
        (window as any).stream = stream// 使流对浏览器控制台可用
        facedata.videoEl.srcObject = stream
        facedata.videoEl.play()
      },
      fnClose() {
        state.showDialog = false
        facedata.canvasEl
          .getContext('2d')
          .clearRect(0, 0, facedata.canvasEl.width, facedata.canvasEl.height)
        facedata.videoEl.pause()
        clearTimeout(facedata.timeout)
        if (typeof (window as any).stream === 'object') {
          (window as any).stream.getTracks().forEach((track: any) => track.stop())
          facedata.videoEl.srcObject = null
        }
      },
      fnError(error: string) {
        alert('视频媒体流获取错误' + error)
      }
    })

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {} as LocationQuery)
    }
    watch([() => route.query, () => facedata.nets, () => facedata.detection], ([query, nets, detection]) => {
      if (query) {
        state.redirect = query.redirect?.toString() ?? ''
        state.otherQuery = getOtherQuery(query)
        facedata.detection = detection
        facedata.videoEl.pause()
        facedata.nets = nets
        methods.fnInit()
        setTimeout(() => {
          facedata.videoEl.play()
          setTimeout(() => methods.fnRun(), 1000)
        }, 1000)
      }
    })
    onMounted(() => {
      methods.fnInit()
      if (state.loginForm.username === '') {
        (userNameRef.value as any).focus()
      } else if (state.loginForm.password === '') {
        (passwordRef.value as any).focus()
      }
    })
    onBeforeUnmount(() => {
      methods.fnClose()
    })
    return {
      userNameRef,
      passwordRef,
      loginFormRef,
      ...toRefs(state),
      ...toRefs(methods)
    }
  }
})
</script>

<style lang="scss">
// References: https://www.zhangxinxu.com/wordpress/2018/01/css-caret-color-first-line/
@supports (-webkit-mask: none) and (not (cater-color: $loginCursorColor)) {
  .login-container .el-input {
    input {
      color: $loginCursorColor;
    }

    input::first-line {
      color: $lightGray;
    }
  }
}

.login-container {
    background: url('../../../assets/images/login/adminLogin.png') 100% 100% no-repeat #73b8ff;
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      height: 47px;
      background: transparent;
      border: 0px;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $lightGray;
      caret-color: $loginCursorColor;
      -webkit-appearance: none;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $loginBg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
  .el-tabs__item {
    color:#cccccc;
    font-size: 1.5rem;
  }
  .el-tabs__item.is-active {
     color: #ffffff;
  }
}
</style>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position:relative;
  // background-color: $loginBg;
  // video {
  //   position: absolute;
  //   /* Vertical and Horizontal center*/
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   z-index: -1;
  // }

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 300px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    font-size: 1.875rem;
    color: #fff;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $darkGray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      height: .625rem;
      font-size: 26px;
      color: $lightGray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    .set-language {
      color: #fff;
      position: absolute;
      top: 3px;
      font-size: 18px;
      right: 0px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $darkGray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
  .see {
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%,-0%);
   canvas {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}
 #myVideo{
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  }
}

.option {
  padding-bottom: 20px;
}
.option div {
  padding: 10px;

}
.option div label {
  margin-right: 20px;
}
  @media only screen and (max-width: 470px) {
  }
}
</style>
